// feature toggles must be listed here to query Unleash for them in UserDetails.jsx
export const H_PLUS_WEB_MAINTENANCE_MODE = 'h-plus-web-maintenance-mode';
export const H_PLUS_PHARMACY_BENEFIT_SEARCH = 'h-plus-pharmacy-benefit-search';
export const H_PLUS_PHARMACY_DISCOUNT_REGISTRATION = 'h-plus-web-pharmacy-discount-registration';
export const H_PLUS_PURCHASE_MEMBERSHIP_V2 = 'h-plus-purchase-membership-V2';
export const PERKS_INCOMPLETE_LOYALTY_REDIRECT = 'PERKS-redirect-on-incomplete-loyalty';
export const CLIPPABLE_PERKS_PLUS_OFFERS = 'clippable-perks-plus-offers-on-web';
export const SEASONS_SEARCH = 'ecomm-enhanced-search-web';
export const SEASONS_SEARCH_IDEAS = 'ecomm-ideas-enhanced-search-web';
export const WEB_ORDER_MIN_FEE = 'web-order-min-fee'; // https://hy-vee.atlassian.net/browse/DGROCERY-2809
