import {GlobalNavigationFooter, GlobalNavigationHeader, GlobalNavigationMainContent, GlobalNavigationSkipToContentLink} from '@hy-vee/global-navigation';
import {NextSeo} from 'next-seo';
import {useRef} from 'react';

import {SEASONS_SEARCH, SEASONS_SEARCH_IDEAS, WEB_ORDER_MIN_FEE} from '../enums/features';
import {HeaderChildren} from '../styles/header-styles';
import {StyledFixedContainer, StyledRelativeContainer} from '../styles/style-constants';
import {redirectToPath} from '../utils/redirect';

import FeatureToggle from './feature-toggle';

const HeaderPage = ({
    background = false,
    customer,
    children,
    seo,
    sticky = false,
}) => {
    const userData = Object.entries(customer || {}).length ? customer : null;
    const HeaderStickyContainer = sticky ? StyledFixedContainer : StyledRelativeContainer;
    const startOfContentRef = useRef(null);

    return (
        <>
            <GlobalNavigationSkipToContentLink startOfContentRef={startOfContentRef} />
            <NextSeo {...seo} />
            <StyledRelativeContainer background={background}>
                <HeaderStickyContainer>
                    <FeatureToggle
                        component={
                            <FeatureToggle
                                component={
                                    <FeatureToggle
                                        component={
                                            <GlobalNavigationHeader
                                                isEcommIdeasEnhancedSearchWeb
                                                isSeasonsSearchFeatureEnabled
                                                isWebOrderMinFeeEnabled
                                                onRouteChange={redirectToPath}
                                            />
                                        }
                                        fallback={
                                            <GlobalNavigationHeader
                                                isEcommIdeasEnhancedSearchWeb
                                                isSeasonsSearchFeatureEnabled
                                                isWebOrderMinFeeEnabled={false}
                                                onRouteChange={redirectToPath}
                                            />
                                        }
                                        featureName={WEB_ORDER_MIN_FEE}
                                    />

                                }
                                fallback={
                                    <GlobalNavigationHeader
                                        isEcommIdeasEnhancedSearchWeb={false}
                                        isSeasonsSearchFeatureEnabled
                                        onRouteChange={redirectToPath}
                                    />
                                }
                                featureName={SEASONS_SEARCH_IDEAS}
                            />
                        }
                        fallback={
                            <GlobalNavigationHeader
                                onRouteChange={redirectToPath}
                            />
                        }
                        featureName={SEASONS_SEARCH}
                    />
                </HeaderStickyContainer>
                <GlobalNavigationMainContent startOfContentRef={startOfContentRef}>
                    <HeaderChildren
                        loggedIn={Boolean(userData)}
                        sticky={sticky}>
                        {children}
                    </HeaderChildren>
                </GlobalNavigationMainContent>
                <GlobalNavigationFooter />
            </StyledRelativeContainer>
        </>
    );
};

export default HeaderPage;
